<template>
  <div class="sticky-top">
    <div class="card border-0 shadow-sm">
      <div class="card-body">
        <h5 class="mb-3">
          <i class="far fa-bullhorn text-primary me-2"></i>Marketing Suite
        </h5>
        <hr />
        <div class="nav-pills flex-column">
          <router-link class="nav-link px-2 pt-2" to="/marketing/client-emails">
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-mail-bulk"></i>
              </div>
              <div class="col-auto text-start my-auto">
                Automated Client Emails
              </div>
            </div>
          </router-link>

          <router-link class="nav-link px-2 pt-2" to="/marketing/signature">
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-signature"></i>
              </div>
              <div class="col-auto text-start my-auto">Email Signature</div>
            </div>
          </router-link>

          <router-link class="nav-link px-2 pt-2" to="/marketing/templates">
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-file-alt"></i>
              </div>
              <div class="col-auto text-start my-auto">Email Templates</div>
            </div>
          </router-link>

          <router-link class="nav-link px-2 pt-2" to="/marketing/email-series">
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-tally"></i>
              </div>
              <div class="col-auto text-start my-auto">Email Series</div>
            </div>
          </router-link>

          <!-- <router-link
            class="nav-link px-2 pt-2"
            to="/marketing/sms"
            :class="path.includes('marketing/sms') ? 'active' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-sms"></i>
              </div>
              <div class="col-auto text-start my-auto">SMS Management</div>
            </div>
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
};
</script>

<style>
</style>
