<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Tabs -->
      <div class="col-md-3 spark-settings-tabs">
        <marketing-nav :user="user" :path="path"></marketing-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h5>Email Communications</h5>
            <div class="row mb-3">
              <div class="col-md-4 mb-3">
                <div
                  class="card border-0 shadow-sm bg-light cursor-pointer"
                  @click="goToUrl('marketing/client-emails')"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4 my-auto">
                        <i class="far fa-4x text-primary fa-magic"></i>
                      </div>
                      <div class="col-8 my-auto">
                        <h5 class="mb-0 text-primary">
                          Automated Client Emails
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div
                  class="card border-0 shadow-sm bg-light cursor-pointer"
                  @click="goToUrl('marketing/signature')"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4 my-auto">
                        <i class="far fa-4x text-primary fa-signature"></i>
                      </div>
                      <div class="col-8 my-auto">
                        <h5 class="mb-0 text-primary">Email Signature</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div
                  class="card border-0 shadow-sm bg-light cursor-pointer"
                  @click="goToUrl('marketing/templates')"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4 my-auto">
                        <i class="far fa-4x text-primary fa-mail-bulk"></i>
                      </div>
                      <div class="col-8 my-auto">
                        <h5 class="mb-0 text-primary">Email Templates</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div class="col-md-4 mb-3">
                <div class="card border-0 shadow-sm bg-light cursor-pointer"
                     @click="goToUrl('marketing/email#scheduler')">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4 my-auto">
                        <i class="far fa-4x text-primary fa-clock"></i>
                      </div>
                      <div class="col-8 my-auto">
                        <h5 class="mb-0 text-primary">Email Scheduler</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->
            </div>
            <!-- <h5>SMS Communications</h5>
            <div class="row">
              <div class="col-md-4 mb-3">
                <div
                  class="card border-0 shadow-sm bg-light cursor-pointer"
                  @click="goToUrl('marketing/sms')"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4 my-auto">
                        <i class="far fa-4x text-primary fa-sms"></i>
                      </div>
                      <div class="col-8 my-auto">
                        <h5 class="mb-0 text-primary">SMS Management</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketingNav from "./partials/MarketingNav";

export default {
  props: ["user", "path"],
  methods: {
    goToUrl(url) {
      this.$router.push(url);
    },
  },
  components: {
    MarketingNav,
  },
};
</script>

<style>
</style>
